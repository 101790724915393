import React from "react";
import "./style.css";

export const ProgressBar = (props) => {
  const { text, percentage } = props;
  return (
    <div className="progress-bar">
      <div className="text-4">{text}</div>
      <div className="result-progress-bar">
        <div className="main-progress-bar">
          <div
            className="inner-progress-bar"
            style={{ width: percentage }}
          ></div>
        </div>
      </div>
    </div>
  );
};
