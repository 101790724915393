import React, { useEffect, useState } from "react";
import { Selector } from "../../components/Selector/Selector";
import { Button } from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import GradIcon from "../../Icon.svg";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";
import { ImCross } from "react-icons/im";
import "./style.css";

export const Homepage = () => {
  const [isMultiple, setIsMultiple] = useState(false);
  const [departmentCount, setDepartmentCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [collegeList, setCollegeList] = useState([]);
  const [degreeList, setDegreeList] = useState([]);
  const [college, setCollege] = useState("");
  const [degree, setDegree] = useState("");
  const [selectedColleges, setSelectedColleges] = useState([]);
  const [selectedDegrees, setSelectedDegrees] = useState([]);
  const [showCollegeList, setShowCollegeList] = useState(false);
  const [showDegreeList, setShowDegreeList] = useState(false);
  const [selectedKey, setSelectedKey] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1);

  const navigate = useNavigate();

  const addDepartment = () => {
    if (departmentCount < 2) {
      setDepartmentCount(departmentCount + 1);
    }
  };

  const closeDepartmentField = () => {
    if (departmentCount > 1) {
      setDepartmentCount(departmentCount - 1);
      setSelectedColleges(selectedColleges.splice(1));
      setSelectedDegrees(selectedDegrees.splice(1));
    }
  };

  const fetchEducations = () => {
    setIsLoading(true);
    api
      .get("/fetchEducations", { params: { education: college } })
      .then((response) => {
        setCollegeList(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const fetchDegrees = () => {
    setIsLoading(true);
    api
      .get("/fetchDegrees", { params: { degree: degree } })
      .then((response) => {
        setDegreeList(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const fetchEducationDegrees = (educationId) => {
    setIsLoading(true);
    api
      .get("/fetchEducationDegrees", { params: { educationId: educationId } })
      .then((response) => {
        console.log(response.data);
        setDegreeList(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleCollegeChange = (e) => {
    setCollege(e.target.value);
    setSelectedColleges("");
  };

  const handleDegreeChange = (e) => {
    setDegree(e.target.value);
    setSelectedDegrees("");
  };

  const handleCollegeClick = (key) => {
    setShowCollegeList(!showCollegeList);
    setSelectedKey(key);
  };

  const handleDegreeClick = (key) => {
    setShowDegreeList(!showDegreeList);
    setSelectedKey(key);
  };

  const selectCollege = (college) => {
    setCollege(college.name);
    setSelectedColleges([...selectedColleges, college.name]);
    setShowCollegeList(false);
    fetchEducationDegrees(college.id);
  };

  const selectDegree = (degree) => {
    setDegree(degree);
    setSelectedDegrees([...selectedDegrees, degree]);
    setShowDegreeList(false);
  };

  const changeTab = (value) => {
    setSelectedTab(value);
    setSelectedColleges([]);
    setSelectedDegrees([]);
    setShowCollegeList(false);
    setShowDegreeList(false);
    setDepartmentCount(1);
    setDegree("");
    setCollege("");
  };

  function setDisable() {
    if (departmentCount > 1) {
      if (selectedTab == 1) {
        return selectedColleges.length < 1 || selectedDegrees < 1;
      } else {
      }
    } else {
      if (selectedTab == 1) {
        return selectedColleges == "" || selectedDegrees == "";
      } else {
        return selectedColleges == "";
      }
    }
  }

  const handleSubmit = () => {
    let data;
    if (departmentCount == 1) {
      data = { colleges: selectedColleges[0], degrees: selectedDegrees[0] };
    } else {
      data = { colleges: selectedColleges, degrees: selectedDegrees };
    }
    // console.log("data", data);
    navigate("/result", { state: data });
  };

  useEffect(() => {
    fetchEducations();
  }, [college]);

  useEffect(() => {
    fetchDegrees();
  }, [degree]);

  return (
    <div className="homepage">
      {isLoading && <Loader />}
      <div className="inner-homepage">
        <div className="headline">
          Decide your undergrad based on after grad statistics
        </div>
        <div className="description">
          Type the university departments you are considering <br /> and well
          show you what will happen after graduation.
        </div>
        <Selector selectedTab={selectedTab} changeTab={changeTab} />
        <div className="analysis">
          <div className="college-department">
            <div className="analysis-fields">
              <div className="input-box">
                <div className="input-label">UNIVERSITY / COLLEGE</div>
              </div>
              {selectedTab == 1 && (
                <div className="input-box">
                  <div className="input-label">DEPARTMENT / SCHOOL</div>
                </div>
              )}
            </div>
            <>
              {Array.from({ length: departmentCount }).map((_, i) => (
                <div className="analysis-fields" key={i}>
                  <div
                    className="input-box"
                    style={{ width: selectedTab == 2 ? "100%" : "48%" }}
                  >
                    <div className="input-text">
                      <input
                        type="text"
                        className="input-field"
                        placeholder="Select university/college"
                        onChange={(e) => handleCollegeChange(e)}
                        value={selectedColleges[i]}
                        onClick={() => handleCollegeClick(i)}
                      />
                      {showCollegeList && selectedKey == i && (
                        <div className="option-list">
                          {collegeList.length > 0 &&
                            collegeList.map((college, index) => {
                              return (
                                <div
                                  className="option"
                                  key={index}
                                  onClick={() => selectCollege(college)}
                                >
                                  {college.name}
                                </div>
                              );
                            })}
                        </div>
                      )}
                      {i == 1 && selectedTab == 2 && (
                        <div
                          className="cross-btn"
                          onClick={() => closeDepartmentField()}
                        >
                          <ImCross />
                        </div>
                      )}
                    </div>
                  </div>
                  {selectedTab == 1 && (
                    <div className="input-box">
                      <div className="input-text">
                        <input
                          type="text"
                          className="input-field"
                          placeholder="Select department/school"
                          onChange={(e) => handleDegreeChange(e)}
                          value={selectedDegrees[i]}
                          disabled={
                            selectedColleges.length <= 1 &&
                            (i != selectedKey || selectedColleges.length == 0)
                          }
                          onClick={() => handleDegreeClick(i)}
                        />
                        {showDegreeList && selectedKey == i && (
                          <div className="option-list">
                            {degreeList.length > 0 &&
                              degreeList.map((degree, index) => {
                                return (
                                  <div
                                    className="option"
                                    key={index}
                                    onClick={() => selectDegree(degree.name)}
                                  >
                                    {degree.name}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                        {i == 1 && (
                          <div
                            className="cross-btn"
                            onClick={() => closeDepartmentField()}
                          >
                            <ImCross />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </>

            {departmentCount < 2 && (
              <div className="add-dept">
                <button
                  className="add-dept-btn"
                  onClick={() => addDepartment()}
                >
                  {selectedTab == 1
                    ? "Add an other university / department"
                    : "Add an other university"}
                </button>
              </div>
            )}
          </div>
          <div
            className={
              "run-button " +
              `${departmentCount == 2 ? "btn-margin-bottom" : ""}`
            }
          >
            {/* <Link to="/result"> */}
            <Button
              text={departmentCount == 2 ? "COMPARE" : "EXPLORE"}
              icon={GradIcon}
              onClick={handleSubmit}
              disabled={setDisable()}
            />
            {/* </Link> */}
          </div>
        </div>
        <ul className="content-container">
          <li className="content-text">
            Compare university departments by their after graduation carreer
            statistics
          </li>
          <li className="content-text">
            Explore the after graduation carreer distribution of university
            departments
          </li>
          <li className="content-text">
            Targeting a specific job in a specific company?
          </li>
          <li className="content-text">
            Compare & explore the ideal university department for a specific job
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};
