import React, { useEffect, useState } from "react";
import "./style.css";
import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const validations = () => {
    const errors = {};

    // Validate email
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    // Validate password
    if (!password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const login = () => {
    const validationError = validations();
    if (Object.keys(validationError).length === 0) {
      setIsLoading(true);
      const payload = {
        email: email,
        password: password,
      };
      api
        .post("/login", payload)
        .then((response) => {
          console.log("response", response);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("accessToken", response.token);
          setIsLoading(false);
          setErrors({});
          navigate("/home");
        })
        .catch((error) => {
          setIsLoading(false);
          setErrors({
            email: "Please enter valid email.",
            password: "Please enter valid password.",
          });
        });
    } else {
      setErrors(validationError);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors({ ...errors, password: null });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: null });
  };

  useEffect(() => {
    console.log("login", isLoggedIn);
    if (isLoggedIn === "true") {
      navigate("/home");
    }
  }, []);
  return (
    <div className="login-page">
      {isLoading && <Loader />}
      <div className="main-login">
        <div className="login-heading">Sign In</div>
        <div className="login-modal">
          <div className="modal-inner-container">
            <div className="login-input-box">
              <div className="login-input-label">Email</div>
              <input
                type="text"
                placeholder="Enter Email"
                className="login-input-field"
                onChange={(e) => handleEmailChange(e)}
              />
              {errors.email != null && (
                <div className="error">{errors.email}</div>
              )}
            </div>
            <div className="login-input-box">
              <div className="login-input-label">Password</div>
              <div className="input-text-box">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  className="login-input-field"
                  onChange={(e) => handlePasswordChange(e)}
                />
                <div
                  className="input-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              {errors.password != null && (
                <div className="error">{errors.password}</div>
              )}
            </div>
            <div className="forgot-password">
              <Link to="/forgot-password">
                <span className="text-link">Forgot Password?</span>
              </Link>
            </div>
            <div className="login-submit-btn" onClick={() => login()}>
              <Button text="Sign in" type="submit" />
            </div>
            <div className="register-link">
              New to AfterGrad?
              <Link to="/register">
                <span className="text-link">&nbsp; Register now</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
