import React from "react";
import "./style.css";

export const Selector = (props) => {
  const { selectedTab, changeTab } = props;
  return (
    <div className="selector">
      <div
        className={"dept-opt " + `${selectedTab == 1 && "selected"}`}
        onClick={() => changeTab(1)}
      >
        COMPARE OR EXPLORE DEPARTMENTS
      </div>
      <div
        className={"dept-opt " + `${selectedTab == 2 && "selected"}`}
        onClick={() => changeTab(2)}
      >
        COMPARE OR EXPLORE UNIVERSITIES
      </div>
    </div>
  );
};
