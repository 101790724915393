import React from "react";
import "./style.css";
import { Button } from "../Button/Button";
import ReactLogo from "../../logo-main.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const Header = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const navigate = useNavigate();
  console.log("location");
  const logout = () => {
    localStorage.setItem("isLoggedIn", false);
    navigate("/login");
  };
  return (
    <div className="header">
      <div className="main-header">
        <Link to={"/"}>
          <img src={ReactLogo} alt="Logo" className="logo" />
        </Link>

        {isLoggedIn === "true" ? (
          <div className="login-btn" onClick={() => logout()}>
            <Button text="Logout" />
          </div>
        ) : (
          location.pathname !== "/login" &&
          location.pathname !== "/register" && (
            <div className="login-btn">
              <Link to="/login">
                <Button text="Register / Signin" />
              </Link>
            </div>
          )
        )}
      </div>
    </div>
  );
};
