import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { ProgressBar } from "../../components/ProgressBar/ProgressBar";
import { Footer } from "../../components/Footer/Footer";
import { Loader } from "../../components/Loader/Loader";
import { useLocation } from "react-router-dom";
import api from "../../AxiosInstance";

export const ResultPage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedList, setSelectedList] = useState(1);
  const [afterGradData, setAfterGradData] = useState({});

  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const resultRef = useRef(null);

  const scrollList = ["start", "center", "end", "nearest"];
  const location = useLocation();

  const { colleges, degrees } = location.state || {};

  const handleRef = (value) => {
    resultRef.current.scrollIntoView({
      behavior: "smooth",
      block: scrollList[value],
    });
  };

  const selectResultIndex = (i) => {
    setSelectedList(i);
    handleRef(i);
  };

  const resultIndex = [
    "Where they do Msc&PhD?",
    "What skills they acquire?",
    "Where they work?",
    "Characteristic career paths",
  ];

  const fetchAfterGradData = () => {
    console.log("colleges", colleges);
    console.log("degrees", degrees);
    setIsLoading(true);
    api
      .get("/fetchAfterGradData", {
        params: { colleges: colleges, degrees: degrees },
      })
      .then((response) => {
        console.log(response.data.data);
        setAfterGradData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAfterGradData();
  }, []);
  return (
    <div className="result-page">
      {isLoading && <Loader />}
      <div className="result-head">
        <div className="inner-result-head">
          <div className="head-section-1">
            <div className="text-1">{colleges + " / " + degrees}</div>
            <div className="text-2">
              Based on career analysis of{" "}
              {afterGradData?.total_professional_count} professionals
            </div>
          </div>
          {afterGradData?.company_position_breakdown?.length > 0 && (
            <div className="head-section-2">
              <div className="text-3">
                {"%" +
                  afterGradData?.company_position_breakdown[0]
                    .professional_percent +
                  " " +
                  afterGradData?.company_position_breakdown[0].org_role}
              </div>
              <div className="text-3">
                @{afterGradData?.company_position_breakdown[0].org_name}
              </div>
            </div>
          )}
          <div className="head-section-2">
            <div className="text-3">%15 Master of Science</div>
            <div className="text-3">
              @Standard University / Computer Science
            </div>
          </div>
          <div className="head-section-2">
            <div className="text-3">After 10 years</div>
            <div className="text-3">%40 CTO @Apple Inc.</div>
          </div>
        </div>
      </div>
      {/* <div className="menu" onClick={() => setShowMenu(!showMenu)}><BsThreeDotsVertical /></div> */}
      <div className="result-body">
        <div className={"result-body-left " + `${showMenu ? "show" : "hide"}`}>
          <div className="result-index">
            {resultIndex.map((value, i) => {
              return (
                <div
                  className={
                    "result-index-list " +
                    `${selectedList == i ? "selected-list" : ""}`
                  }
                  onClick={() => selectResultIndex(i)}
                  key={i}
                >
                  {value}
                </div>
              );
            })}
          </div>
        </div>
        <div className="result-body-right" ref={resultRef}>
          <div className="result-data-1">
            <div className="result-data-head">
              <div className="text-1">
                Company & position specific breakdown
              </div>
              <div className="text-2">In which position&company they work?</div>
            </div>
            {afterGradData?.company_position_breakdown?.length > 0 && (
              <div className="result-data-body">
                {afterGradData.company_position_breakdown.map((value, key) => {
                  return (
                    <ProgressBar
                      key={key}
                      text={
                        "%" +
                        value?.professional_percent +
                        " - " +
                        value.professional_count +
                        " graduates " +
                        " | " +
                        value.org_role +
                        " @" +
                        value.org_name
                      }
                      percentage={value.professional_percent + "%"}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className="result-data-1">
            <div className="result-data-head">
              <div className="text-1">Company breakdown</div>
              <div className="text-2">In which company they work?</div>
            </div>
            <div className="result-data-body">
              {afterGradData.company_breakdown?.length > 0 &&
                afterGradData.company_breakdown.map((value, key) => {
                  return (
                    <ProgressBar
                      key={key}
                      text={
                        "%" +
                        value.professional_percent +
                        " - " +
                        value.professional_count +
                        " graduates " +
                        " | " +
                        " @" +
                        value.org_name
                      }
                      percentage={value.professional_percent + "%"}
                    />
                  );
                })}
            </div>
          </div>
          <div className="result-data-1">
            <div className="result-data-head">
              <div className="text-1">Characteristic Carreer paths</div>
              <div className="text-2">
                How is their after graduation carreer paths in terms of position
                & company?
              </div>
            </div>
            {afterGradData.after_one_year_data?.length > 0 && (
              <div className="main-data-body">
                <div className="data-body-label">Up to 1 year after grad</div>
                <div className="result-data-body">
                  {afterGradData.after_one_year_data.map((value, key) => {
                    return (
                      <ProgressBar
                        key={key}
                        text={
                          "%" +
                          value.professional_percent +
                          " - " +
                          value.professional_count +
                          " graduates " +
                          " | " +
                          " @" +
                          value.org_name
                        }
                        percentage={value.professional_percent + "%"}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {afterGradData.after_one_to_three_year_data?.length > 0 && (
              <div className="main-data-body">
                <div className="data-body-label">1 to 3 years after grad</div>
                <div className="result-data-body">
                  {afterGradData.after_one_to_three_year_data.map(
                    (value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            " @" +
                            value.org_name
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
            {afterGradData.after_three_to_five_year_data?.length > 0 && (
              <div className="main-data-body">
                <div className="data-body-label">3 to 5 years after grad</div>
                <div className="result-data-body">
                  {afterGradData.after_three_to_five_year_data.map(
                    (value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            " @" +
                            value.org_name
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
