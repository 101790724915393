import React from "react";
import { Oval } from "react-loader-spinner";
import "./style.css";

export const Loader = () => {
  return (
    <div className="loader">
      <Oval
        visible={true}
        height="80"
        width="80"
        color="#f67b01"
        secondaryColor="#ffe6cd"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};
