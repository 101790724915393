import React from 'react';
import "./style.css";

export const Footer = () => {
  return (
    <div className='footer'>
        <div className='main-footer'>
            &#169; Copyright 2024
        </div>
    </div>
  )
}
